<template>
  <Loader :value="promises.fetch" class="flatfinder-page">
    <Blocks :blocks="config" :customComponents="customComponents" />
  </Loader>
</template>

<script>
import BrowserApiMixin from '@/mixins/browser-api'
import { mapActions, mapState } from 'vuex'
import CustomComponents from '@/components/Custom'

import Config from '@/config/enhetsvelger'
import { Blocks } from '@kvass/pagebuilder'

export default {
  mixins: [BrowserApiMixin('flatfinder')],
  computed: {
    ...mapState('Root', ['promises', 'item']),
    config() {
      return Config.call(this)
    },
    customComponents() {
      return { ...CustomComponents }
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$path('item.customFields.title-overrides.flatfinder') || this.$path('flatfinder'),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.post-page {
  .plans {
    .kpb-gallery__item {
      border: 1px solid #{$dark-grey};
    }
  }
}
</style>
