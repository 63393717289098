import Cta from './partials/cta'

export default function () {
	const get = path => this.$path(path, this.$store.state)

	function getLocation(type) {
		if (type === 'flatfinder') {
			let areas = (get('Project.item.posts') || []).find(
				post => (post.customFieldsConfig[0] || {}).source === 'areas',
			)

			if (areas) {
				return `/${areas.slug}#section-${type}`
			}
		}

		return `#${type}`
	}

	return [
		{
			component: 'Section',
			props: {
				width: 'large'
			},
			blocks: {
				default: {
					component: 'Flatfinder',
					data: {
						flatfinders: get('Root.item.flatfinders'),
					}
				},
			}
		},
		{
			component: 'Section',
			props: {
				width: 'medium'
			},
			blocks: {
				default: {
					component: 'Residentials',
					data: {
						project: get('Root.item.id'),
					},
					props: {
						disableNav: false,
						showStatus: true,
						title: get('Root.item.customFields.title-overrides.residentials'),
					}
				}
			}
		},
		Cta(get)
	]
}
